export const snackbarMiddleware = (snackbarDispatch, i18n) => store => next => action => {
  const state = store.getState();
  const {
    dispatch
  } = store;
  const showSnackbar = (message, snackbarAction) => {
    snackbarDispatch({
      type: 'add-snackbar',
      snackbarEntry: {
        message,
        action: snackbarAction
      }
    });
  };
  switch (action.type) {
    case 'remove-history-entry':
      {
        showSnackbar(i18n.t('global.removedHistoryEntry'), {
          text: i18n.t('global.undo'),
          onClick: () => {
            dispatch({
              type: 'set-history',
              history: state.videoHistory
            });
            showSnackbar(i18n.t('global.actionUndone'));
          }
        });
        break;
      }
    case 'remove-watch-again':
    case 'remove-continue-watching':
      {
        let title = i18n.t('global.continueWatching');
        if (action.type === 'remove-continue-watching') {
          title = i18n.t('myVideos.watchAgain');
        }
        showSnackbar(i18n.t('global.wasDeleted', {
          title
        }), {
          text: i18n.t('global.undo'),
          onClick: () => {
            dispatch({
              type: 'set-history',
              history: state.videoHistory
            });
            showSnackbar(i18n.t('global.actionUndone'));
          }
        });
        break;
      }
    default:
  }
  next(action);
};