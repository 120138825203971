import { showHistoryStorage } from '../../storage/show-history-storage';
import { videoHistoryStorage } from '../../storage/video-history-storage';
export const storageMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    case 'update-progress':
    case 'watch-again-loaded':
    case 'continue-watching-loaded':
      {
        videoHistoryStorage.set(state.videoHistory);
        showHistoryStorage.set(state.showHistory);
        break;
      }
    default:
  }
};