import { produce } from 'immer';
import { isPersonalizationActive } from '../../storage/personalization-settings';
import { isoDate } from '../../utils/date-formatter';
import { logAction } from '../../utils/log-action';
export const initialState = {
  videoHistory: [],
  videoHistoryById: {},
  showHistory: [],
  continueWatchingIds: [],
  watchAgainIds: []
};
export const reducer = (state, action) => {
  logAction(state, action);
  return produce(state, draft => {
    switch (action.type) {
      case 'set-history':
        {
          draft.videoHistory = action.history;
          updateState(draft);
          return draft;
        }
      case 'set-show-history':
        {
          draft.showHistory = action.history;
          return draft;
        }
      case 'remove-history-entry':
        {
          draft.videoHistory = draft.videoHistory.filter(videoProgress => videoProgress.id !== action.videoProgressId);
          updateState(draft);
          return draft;
        }
      case 'remove-continue-watching':
        {
          draft.videoHistory = draft.videoHistory.filter(videoProgress => !videoProgress.progressInPercentage || videoProgress.progressInPercentage >= maxWatchedPercentage);
          updateState(draft);
          return draft;
        }
      case 'remove-watch-again':
        {
          draft.videoHistory = draft.videoHistory.filter(videoProgress => !videoProgress.progressInPercentage || videoProgress.progressInPercentage < maxWatchedPercentage);
          updateState(draft);
          return draft;
        }
      case 'update-progress':
        {
          if (isPersonalizationActive()) {
            // update video history
            draft.videoHistory = draft.videoHistory.filter(entry => entry.id !== action.progress.id);
            draft.videoHistory.unshift(action.progress);

            // update show history
            if (draft.lastTrackedMediaUrn !== action.mediaUrn && action.showUrn) {
              const showHistoryEntry = draft.showHistory.find(entry => entry.urn === action.showUrn);
              draft.showHistory = draft.showHistory.filter(entry => entry.urn !== action.showUrn);
              if (showHistoryEntry) {
                showHistoryEntry.lastViewedDate = isoDate(new Date());
                showHistoryEntry.mediaViewCount += 1;
                draft.showHistory.unshift(showHistoryEntry);
              } else {
                draft.showHistory.unshift({
                  urn: action.showUrn,
                  lastViewedDate: isoDate(new Date()),
                  mediaViewCount: 1
                });
              }
            }
            updateState(draft);
            draft.lastTrackedMediaUrn = action.mediaUrn;
          }
          return draft;
        }
      case 'continue-watching-loaded':
        {
          const medias = action.medias.filter(media => media.type !== 'LIVESTREAM');
          const videoIdsToRemove = draft.continueWatchingIds.filter(id => {
            const found = medias.find(media => media.id === id);
            return !found;
          });
          draft.videoHistory = draft.videoHistory.filter(historyEntry => !videoIdsToRemove.includes(historyEntry.id));
          updateState(draft);
          return draft;
        }
      case 'watch-again-loaded':
        {
          const medias = action.medias.filter(media => media.type !== 'LIVESTREAM');
          const videoIdsToRemove = draft.watchAgainIds.filter(id => {
            const found = medias.find(media => media.id === id);
            return !found;
          });
          draft.videoHistory = draft.videoHistory.filter(historyEntry => !videoIdsToRemove.includes(historyEntry.id));
          updateState(draft);
          return draft;
        }
      default:
    }
  });
};
const historyCount = 100;
const continueWatchingCount = 20;
const watchAgainCount = 20;
const minWatchedSeconds = 10;
export const maxWatchedPercentage = 98;
export const updateState = draft => {
  draft.videoHistory = draft.videoHistory.filter(entry => entry.progressInSeconds >= minWatchedSeconds).slice(0, historyCount);
  draft.continueWatchingIds = draft.videoHistory.filter(entry => !entry.progressInPercentage || entry.progressInPercentage < maxWatchedPercentage).slice(0, continueWatchingCount).map(entry => entry.id);
  draft.watchAgainIds = getWatchAgainIds(draft.videoHistory);
  draft.videoHistoryById = {};
  draft.videoHistory.forEach(entry => {
    draft.videoHistoryById[entry.id] = entry;
  });
};
export const getWatchAgainIds = videoHistory => videoHistory.filter(entry => !entry.progressInPercentage || entry.progressInPercentage >= maxWatchedPercentage).slice(0, watchAgainCount).map(entry => entry.id);