import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-showHistory';
const defaultValue = {
  history: []
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem().history;
};
const set = history => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem({
    history
  }).history;
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(defaultValue);
};
export const showHistoryStorage = {
  get,
  set,
  reset
};