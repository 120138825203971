import { isRtsUserLoggedIn, loadRtsVideoHistory, updateRtsHistory } from '../../utils/rts-login-helper';
const updateIntervalInSeconds = 5;
export const createRtsVideoHistoryMiddleware = axios => {
  const videoIdToLastTrackedPosition = {};
  return store => next => action => {
    switch (action.type) {
      case 'load-history':
      case 'update-progress':
        {
          if (isRtsUserLoggedIn()) {
            next(action);
          } else {
            // Do nothing for these actions if we're not logged in
            return;
          }
          break;
        }
      default:
        {
          next(action);
        }
    }
    switch (action.type) {
      case 'load-history':
        loadRtsVideoHistory(axios).then(history => store.dispatch({
          type: 'set-history',
          history
        }));
        break;
      case 'update-progress':
        {
          const {
            progress
          } = action;
          const lastTrackedPosition = videoIdToLastTrackedPosition[progress.id] || undefined;
          if (!lastTrackedPosition || Math.abs(progress.progressInSeconds - lastTrackedPosition) >= updateIntervalInSeconds) {
            updateRtsHistory(progress, axios);
            videoIdToLastTrackedPosition[progress.id] = progress.progressInSeconds;
          }
          break;
        }
      default:
    }
  };
};